import { useDialog } from "naive-ui";
import rulesConstants from "../constants/rules.constant.js";
import moment from "moment-timezone";
// export const ApiToken =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIwNzc3YjhiOC04MDg5LTQwMDMtOWFiYy03Y2UwNGFkNzQ2NWMiLCJ2YWxpZCI6IjEiLCJuYW1lIjoiYWRtaW5Ac2hhYmRoYW0uY29tIiwicGFzc3dvcmQiOiIxMjM0MTIzNCIsImV4cCI6MTcxMTQ0ODIyOCwiaXNzIjoiaHR0cDovL215c2l0ZS5jb20iLCJhdWQiOiJodHRwOi8vbXlzaXRlLmNvbSJ9.I-hXQJ_LZhMNszGPFEpBFAs_GzjBxHtpoipkTP0WSKo";
// export const ApiPrefix = "https://shabdhamapitest.convenientcare.life/api/";

export const ApiToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxYWIyZjM5Ny03OWFkLTRlZTUtYjYwZS1hMDAyYTBjNDMxYTIiLCJ2YWxpZCI6IjEiLCJuYW1lIjoiZGlyZWN0b3JAbW9jZXJvaGVhbHRoLmluIiwicGFzc3dvcmQiOiIxMjM0MTIzNCIsImV4cCI6MTcyNTI4ODkxNiwiaXNzIjoiaHR0cDovL215c2l0ZS5jb20iLCJhdWQiOiJodHRwOi8vbXlzaXRlLmNvbSJ9.nkFicnugEBZzcpEWIZ5GW7hCj6XR_n3BhbxVkOEgReE";

  export const ApiPrefix = "https://demohmsapi.convenientcare.life/api/";

export const ImagePrefix =
  "https://demohmsapi.convenientcare.life/formbuilder/";

export const formatDate = (date) => {
  return moment(date).local().format("MM/DD/YYYY hh:mm A");
};
export const formatDateOnly = (date) => {
  return moment(date).local().format("DD-MMM-YYYY");
};
export const getDate = () => {
  return moment().format("YYYY-MM-DDTHH:mm:ss");
};
export const getRawDate = (date) => {
  return moment(date);
};
export function getRules(rules) {
  if (!rules) return [];
  rules = rules.split("|");
  let list = [];
  rules.forEach((rule) => {
    if (rulesConstants.rules[rule]) list.push(rulesConstants.rules[rule]);
  });
  return list;
}
export function formatPrice(n) {
  if (!n) return "0";
  return new Intl.NumberFormat("en-IN", { maximumSignificantDigits: 3 }).format(
    n
  );
}
export function formatMiles(n) {
  if (!n) return "0";
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function getDoc(form, doc) {
  form = form || {};
  Object.keys(form).forEach((key) => {
    if (form[key] != undefined) doc[key] = form[key];
  });
  return doc;
}
export function initForm(form, doc) {
  doc = doc || {};
  Object.keys(form).forEach((key) => {
    if (doc[key] != undefined) {
      form[key] = doc[key];
    }
  });
}

export function formatHeaders(doc, width) {
  let list = [];
  width = width || {};
  Object.keys(doc).forEach((value) => {
    list.push({ value, text: doc[value], width: width[value] });
  });
  return list;
}
export const confirmDialog = ({ message, onConfirm, onClose }) => {
  useDialog().error({
    title: "Confirm",
    content: message || "Are you sure?",
    positiveText: "Okay",
    negativeText: "Cancel",
    onPositiveClick: () => {
      if (onConfirm) onConfirm();
    },
    onNegativeClick: () => {
      if (onClose) onClose();
    },
  });
};

export const getSettings = (item) => {
  if (!item) return {};
  let settings = item.Form_settings || {};
  if (typeof settings == "string") {
    try {
      settings = JSON.parse(settings);
    } catch (error) {
      console.log(error);
    }
  }
  return settings;
};
export const parseJson = (str) => {
  try {
    return JSON.parse(str);
  } catch (error) {
    return {};
  }
};

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
      console.log(reader.result);
    };
    reader.onerror = function (error) {
      reject(error);
      console.log("Error: ", error);
    };
  });
}
